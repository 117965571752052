<template>
	<!-- 报检汇总 -->
	<div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
			<div class="title">合同付款记录汇总</div>
			<div class="line"></div>
			<el-row style="margin-bottom: 5px;">
				<el-form ref="form" :model="form" inline label-width="70px" label-position="left">
					<el-row
						style="background-color: rgb(243, 247, 247);margin-top: -10px;margin-left: -20px;height: 45px;width: 103%;">
						<el-col :span="23" style="margin-left:20px">
							<el-form-item label="合同名称" class="selFormItem">
								<el-input class="selSearchInput" v-model="contractName" type="input" rows="1"
									style="width:150px" placeholder="请填写"></el-input>
							</el-form-item>
							<el-form-item label="到账时间" class="selFormItem" style="margin-top:5px">
								<el-date-picker class="selDateInput" v-model="contractTime" type="daterange"
									value-format="yyyy-MM-dd" range-separator="~" start-placeholder="开始日期"
									end-placeholder="结束日期">
								</el-date-picker>
							</el-form-item>
							<el-form-item label="开票时间" class="selFormItem" style="margin-top:5px">
								<el-date-picker class="selDateInput" v-model="billTime" type="daterange"
									value-format="yyyy-MM-dd" range-separator="~" start-placeholder="开始日期"
									end-placeholder="结束日期">
								</el-date-picker>
							</el-form-item>
							<el-button style="margin-top:8px" v-preventReClick class="btn" type="primary" size="small"
								@click="selGetDataList()" v-if="showSearch">查询</el-button>
							<el-button v-preventReClick class="rebtn" size="small" @click="resetFormData()">重置</el-button>
						</el-col>
					</el-row>
				</el-form>
			</el-row>
		</el-card>
		<div>
			<el-card style="margin-top: -28px; height: 590px;" body-style="height:550px; padding: 0;">
				<el-row style="height: 100%; margin-bottom: 10px;">
					<el-col :span="24" style="height: 100%;">
						<div class="box">
							<el-table height="530" :row-class-name="tableRowClassName" class="dataListTable" border
								:data="dataList" header-align="center" style="width: 100%;margin-bottom: 20px;"
								header-cell-class-name="stepPointTHeader" :cell-style="{ padding: '0px' }">
								<el-table-column type="index" label="NO." width="60" align="center">
									<template slot-scope="scope">{{ (pageIndex2 - 1) * pageSize + scope.$index + 1
									}}</template>
								</el-table-column>
								<el-table-column prop="contractName" label="合同名称" align="center">
								</el-table-column>
								<el-table-column prop="collectionItem" label="付款项目" align="center">
								</el-table-column>
								<el-table-column prop="billAmount" label="付款金额" align="center">
								</el-table-column>
								<el-table-column prop="billDate" label="付款日期 " align="center">
								</el-table-column>
								<el-table-column prop="billNo" label="发票号 " align="center">
								</el-table-column>

								<el-table-column prop="remark" label="备注 " align="center">
								</el-table-column>
								<!--			   <el-table-column prop="billFlag" label="开票标识" align="center">-->
								<!--			  	<template slot-scope="scope">-->
								<!--			  		<span v-if="scope.row.billFlag == 1">已开票</span>-->
								<!--			  		<span v-if="scope.row.billFlag == 0">未开票</span>-->
								<!--			  	</template>-->
								<!--			   </el-table-column>-->
								<!--			   <el-table-column prop="accountAmount" label="到账金额" align="center">-->
								<!--			   </el-table-column>-->
								<!--			   <el-table-column prop="accountDate" label="到账时间" align="center">-->
								<!--			   </el-table-column>-->
								<!--			   <el-table-column prop="accountFlag" label="到账标识" align="center">-->
								<!--			  	 <template slot-scope="scope">-->
								<!--			  	 	<span v-if="scope.row.billFlag == 1">已到账</span>-->
								<!--			  	 	<span v-if="scope.row.billFlag == 0">未到账</span>-->
								<!--			  	 </template>-->
								<!--			   </el-table-column>-->
							</el-table>
						</div>
						<el-pagination class="my-pagination" @size-change="sizeChangeHandle"
							@current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]"
							:page-size="pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
						</el-pagination>
					</el-col>
				</el-row>
			</el-card>
		</div>
	</div>
</template>
<script>
import $common from "@/utils/common.js"
export default {
	data() {
		return {
			showSearch: false,
			form: {},
			contractName: "",
			contractTime: [],
			billTime: [],
			contratStateList: [],
			stateFlg: "",
			dataList: [],
			//当前页码
			pageIndex: 1,
			// 显示序号
			pageIndex2: 1,
			//当前记录数
			pageSize: 20,
			//总页数
			totalPage: 0,
		};
	},
	components: {
	},
	mounted() {
		this.showSearch = $common.isAuth('ownerContract:payment:search');
		this.getDataList();
	},
	methods: {
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 260;
			return { height: height + 'px' }
		},
		handleSelectionChange(val) {
			this.tableSelVal = val;
		},
		tableRowClassName({ row, rowIndex }) {
			let color = ''
			for (let item of this.tableSelVal) {
				if (item.id === row.id) color = 'table-SelectedRow-bgcolor'
			}
			return color
		},
		currentChangeHandle(val) {
			this.pageIndex = val
			this.getDataList()
		},
		// 每页数
		sizeChangeHandle(val) {
			this.pageSize = val
			this.pageIndex = 1
			this.getDataList()
		},
		getDataList() {
			if (this.showSearch) {
				this.$http({
					url: this.$store.state.httpUrl + "/business/ownercontractcollection/listBySubjectNo",
					method: "get",
					params: {
						workNo: $common.getItem("workNo"),
						subjectNo: $common.getItem("subjectNo"),
						accountBeginTime: this.contractTime[0] ? this.contractTime[0] : "",
						accountEndTime: this.contractTime[1] ? this.contractTime[1] : "",
						billBeginTime: this.billTime[0] ? this.billTime[0] : "",
						billEndTime: this.billTime[1] ? this.billTime[1] : "",
						contractName: this.contractName,
						stateFlg: this.stateFlg,
						limit: this.pageSize,
						page: this.pageIndex
					},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.dataList = data.body.records;
						this.totalPage = data.body.totalCount;
						this.pageIndex2 = this.pageIndex
					}
				});
			}
		},
		resetFormData() {
			this.contractName = "";
			this.contractTime = [];
			this.billTime = [];
			this.stateFlg = "";
		},
		selGetDataList() {
			this.pageIndex = 1;
			this.getDataList();
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
	}
};
</script>
<style scoped>
.selItemText {
	font-size: 14px;
	color: #1c1c1c;
	display: inline-block;
	width: 80px;
}

.my-pagination {
	margin-right: 20px !important;
}

::v-deep(.selSearchInput .el-input__inner) {
	width: 150px !important;
	height: 30px !important;
}

.selDateInput {
	width: 280px !important;
	margin-top: 2px;
	height: 32px;

	::v-deep .el-input__inner {
		height: 33px;
	}
}

::v-deep(.selDateInput .el-range-separator) {
	width: 40px !important;
	margin-top: -8px;
}

::v-deep(.selFormItem .el-form-item__label) {
	font-size: smaller;
}
</style>
